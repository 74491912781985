<template>
  <v-app-bar
    clipped-left
    app
    dark
    height='60'
    fixed
  >
    <v-app-bar-nav-icon @click="toggleSidebar" v-if="loggedIn" id="hamburger-btn">
      <v-icon>menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      <v-container>
        <v-img
          class="logo mr-2"
          src="../../assets/logo.png"
          contain
          height="40"
          width="40"
          alt="Buzz Solutions"
          @click="$router.push({ name: 'Landing' }).catch((e) => e)"
          style="cursor:pointer"
        ></v-img>
      </v-container>
    </v-toolbar-title>
    <v-toolbar-items v-if="!isMobile">
      <!-- <v-select
        v-if="loggedIn && showProjectsOnThisRoute"
        :items="projects"
        item-value="pid"
        item-text="name"
        :value="currentProject"
        id="projects-select"
        class="mt-3"
        @change="enterProject"
        solo
        dense
        :loading="loadingProjects"
      ></v-select> -->
      <RedirectByProject />
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-dialog
      v-if="loggedIn"
      v-model="dialog"
      width="650"
    >

      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom >
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-on="{ ...dialog, ...tooltip }" v-bind="attrs">
              help
            </v-icon>
          </template>
          Click to watch tutorial video
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Power-AI Tutorial</v-card-title>

        <v-card-text style="margin-top: 10px">
          <video v-if="dialog" width="600" controls src="https://storage.googleapis.com/buzz-powerai-static--asset-access/PowerAI%20Platform%20Video.mp4" autoplay />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar-items>
      <v-tab
        v-for="tab in tabList"
        :key="tab.title"
        ripple
        :to="tab.path"
        class="navbar-tab"
        :id="tab.id"
        @click="onClickTab(tab.title)"
      >
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import links from '@components/layouts/links';
import RedirectByProject from '@components/layouts/RedirectByProject.vue';

export default {
  name: 'Navbar',
  props: ['isMobile', 'drawerStatus'],
  components: {
    RedirectByProject,
  },
  data: () => ({
    projects: [],
    dialog: false,
    window: {
      width: 0,
      height: 0,
    },
    miniSidebar: false,
    drawer: true,
    showProjectsOnThisRoute: true,
  }),
  methods: {
    ...mapActions(['logout', 'getProjects']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    toggleSidebar() {
      this.drawer = !this.drawer;
      this.$emit('toggleSidebar', this.drawer);
    },
    adjustSidebar() {
      this.miniSidebar = !this.miniSidebar;
      this.$emit('toggleSidebar', !this.miniSidebar);
    },
    enterProject(pid) {
      this.$router.push({ name: 'Redirect', params: { pid, name: this.$route.name } });
    },
    async onClickTab(tabTitle) {
      if (tabTitle === 'Logout') {
        await this.logout();
        this.$router.push({ name: 'Login' });
      }
    },
    showProjects() {
      const blackList = ['InsightsContent'];
      this.showProjectsOnThisRoute = !blackList.includes(this.$route.name);
    },
  },
  computed: {
    ...mapGetters(['loggedIn', 'currentUser', 'allProjects', 'currentProject', 'loadingProjects', 'allTeams', 'firstFactorAuthenticated', 'secondFactorAuthenticated']),
    tabList() {
      let tabs;
      if (this.authenticationLevel === 'Authenticated') {
        tabs = links.auth;
        const settingsTabIndex = tabs.findIndex((tab) => tab.title === 'Settings');
        tabs[settingsTabIndex].path = `/settings/user/${this.currentUser.uid}/profile`;
      } else if (this.authenticationLevel === 'First Factor Authenticated') {
        const [logoutTab] = links.auth.filter((link) => link.title === 'Logout');
        tabs = [logoutTab];
      } else {
        tabs = links.guest;
      }

      if (this.isMobile) return [];
      return tabs;
    },
    activeProject() {
      return this.$route.name === 'Project' ? this.currentProject : null;
    },
    authenticationLevel() {
      if (this.loggedIn) return 'Authenticated';
      if (this.firstFactorAuthenticated && !this.secondFactorAuthenticated) return 'First Factor Authenticated';
      return 'Not Authenticated';
    },
    // showClientLogo() {
    //   return window.location.hostname === 'newfoundland.buzzpowerai.com';
    // },
  },
  watch: {
    drawerStatus(newStatus) {
      this.drawer = newStatus;
    },
    $route() {
      this.showProjects();
    },
  },
  async created() {
    this.showProjects();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    let res;

    if (this.loggedIn && this.allProjects.length === 0) {
      if (this.currentUser.adminStatus) {
        res = await this.getProjects(`company_id=${this.currentUser.cid}`);
      } else {
        // Get User Projects
        res = await this.getProjects(`company_id=${this.currentUser.cid}&user_id=${this.currentUser.uid}`);
      }
      this.projects = res.data;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
