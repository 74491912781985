<template>
  <div class="project">
    <Loading v-if="loadingProject" />
    <v-container v-else fluid>
      <h1>Project Description: </h1>
      <v-row>
        <v-col md="9">
          <ProjectDataTable :project="currentProject" />
        </v-col>
        <v-col md="3">
          <div class="my-2">
            <v-btn id="back-to-projects"
                   color="primary"
                   to="/projects/view/grid" small>Back To Projects</v-btn>
          </div>
          <div class="my-2">
            <v-btn
              color="primary"
              :to="`/project/${currentProject.pid}/edit`"
              small
              id="edit-project"
              v-if="hasPermission('PROJECT_WRITE')"
            >
              Edit Project
            </v-btn>
          </div>
          <div class="my">
            <RetrainModelButton />
          </div>
          <div class="my-2">
            <v-btn
              color="primary"
              small
              id="image-ai-processing-btn"
              @click="goToImageRoute"
            >
              Images & AI Processing
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="loadingImages">
      <div style="text-align: center">
        <div>
          <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
          ></v-progress-circular>
        </div>
        <div>
          Loading images...
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';
import ProjectDataTable from '@components/projects/ProjectDataTable.vue';
import RetrainModelButton from '@components/projects/RetrainModelButton.vue';

export default {
  name: 'Project',
  components: {
    Loading,
    ProjectDataTable,
    RetrainModelButton,
  },
  props: ['pid'],
  data: () => ({
    imageList: [],
    loadingImages: false,
  }),
  methods: {
    ...mapActions(['getProject', 'getImagesByProject']),
    async fetchProject() {
      await this.getProject({ pid: this.pid, cid: this.currentCompany.cid });
    },
    async goToImageRoute() {
      this.loadingImages = true;
      const { pid, cid } = this.currentProject;
      const { data } = await this.getImagesByProject({ cid, pid });
      const imgURL = `/project/${this.currentProject.pid}/images/original/${(data.length > 0) ? data[0].filename : ''}`;
      await this.$router.push(imgURL);
      this.loadingImages = false;
    },
  },
  computed: {
    ...mapGetters(['loadingProject', 'hasPermission', 'currentProject', 'currentCompany']),
  },
  created() {
    this.fetchProject();
  },
  watch: {
    pid: 'fetchProject',
  },
};
</script>
